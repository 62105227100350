(function () {
  'use strict';

  angular
    .module('app.config', [
      'app.config.core',
      'ui.router',
      'pascalprecht.translate',
      'LocalForageModule',
    ])
    .config(configureTranslations)
    .config(configureLazyLoading)
    .config(configureStates)
    .config(configureGeneral)
    .config(configureMaterialIcons)
    .config(configureMaterialTheme);

  function configureTranslations($translateProvider) {
    $translateProvider.preferredLanguage('en_US');
    $translateProvider.useSanitizeValueStrategy();
    $translateProvider.useMissingTranslationHandler('MissingTranslationReporter');
  }

  function configureLazyLoading($provide, $animateProvider) {
    $animateProvider.classNameFilter(/^((?!(fa-spinner)).)*$/);

    $provide.decorator('$ocLazyLoad', function ($delegate, $q, $browser, BUILD) {
      // modified code from ocLazyLoad sources:
      // we need to inform angular about our lazy loading requests, otherwise Protractor won't wait for them
      $delegate.jsLoader = function (paths, callback, params) {
        var promises = [];
        angular.forEach(paths, function (path) {
          promises.push($delegate.buildElement('js', path, params));
        });

        $browser.$$incOutstandingRequestCount();
        $q.all(promises).then(
          function () {
            callback();
            $browser.$$completeOutstandingRequest(angular.noop);
          },
          function (err) {
            callback(err);
            $browser.$$completeOutstandingRequest(angular.noop);
          }
        );
      };

      return $delegate;
    });
  }

  function configureStates($provide, $urlRouterProvider, $stateProvider, $locationProvider) {
    $locationProvider.html5Mode(true);

    $urlRouterProvider.when('', '/platform');
    $urlRouterProvider.when('/', '/platform');

    $stateProvider
      .state('root', {
        views: {
          '@': {
            templateUrl: 'layout/views/commonLayout.html',
            controller: 'LayoutController as $layoutCtrl',
          },
          'sidebar@root': {templateUrl: 'layout/views/sidebar.html'},
          'header@root': {
            templateUrl: 'layout/views/header.html',
            controller: 'HeaderController as $headerCtrl',
          },
        },
      })
      .state('root.unauthorized', {
        url: '/unauthorized',
        views: {
          'contentView@root': {
            templateUrl: 'common/acl/views/unauthorized.html',
          },
        },
      });

    // make `$state.href` be aware of `redirectTo`
    $provide.decorator('$state', function ($delegate, $injector) {
      var originalFunction = $delegate.href;

      $delegate.href = function (stateName, params) {
        var state = $delegate.get(stateName),
          redirection = state ? state.redirectTo : null;

        if (typeof redirection === 'function') {
          redirection = state.redirectTo(params, $injector);
        }

        return originalFunction.call(this, redirection ? redirection : stateName, params);
      };

      return $delegate;
    });
  }

  function configureGeneral(
    $compileProvider,
    SCConfigurationProvider,
    $httpProvider,
    jwtInterceptorProvider,
    API_END_POINT,
    VIDEO_UPLOAD_END_POINT
  ) {
    SCConfigurationProvider.configureEndpoint(API_END_POINT);
    SCConfigurationProvider.configureVideoUploadEndpoint(VIDEO_UPLOAD_END_POINT);

    $httpProvider.interceptors.push('jwtInterceptor');
    $httpProvider.interceptors.push('AdminLoginRequestInterceptor');
    $httpProvider.interceptors.push('SeasonInterceptorFactory');
    $httpProvider.interceptors.push('FreshTokenInterceptor');
    $httpProvider.interceptors.push('HttpErrorInterceptor');

    jwtInterceptorProvider.tokenGetter = [
      'config',
      'UserService',
      function (config, UserService) {
        if (config.url.indexOf(API_END_POINT) === 0) {
          return UserService.getToken();
        }

        return null;
      },
    ];
  }

  function configureMaterialIcons($mdIconProvider, $$mdSvgRegistry) {
    $mdIconProvider
      .icon('md-close', $$mdSvgRegistry.mdClose)
      .icon('md-menu', $$mdSvgRegistry.mdMenu)
      .icon('md-toggle-arrow', $$mdSvgRegistry.mdToggleArrow);
  }

  function configureMaterialTheme($mdThemingProvider) {
    $mdThemingProvider.theme('default').primaryPalette('blue').accentPalette('blue');
    $mdThemingProvider.theme('dark-blue').backgroundPalette('blue').dark();
  }
})();
